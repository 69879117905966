<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  name: 'ManageTheme',
  components: {
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    ColorPicker: () => import('@/components/general/ColorPicker'),
    // IconPicker: () => import('@/components/general/IconPicker'),
    InputField: () => import('@/components/general/InputField')
  },

  data () {
    return {
      formData: {
        id: null,
        name: null,
        alias: null,
        color: null,
        icon: null,
        active: true
      },

      themeIcons: [
        {
          img_path: '/assets/images/themes/default/svg/computer.svg',
          name: 'computer'
        },
        {
          img_path: '/assets/images/themes/default/svg/handbag.svg',
          name: 'handbag'
        },
        {
          img_path: '/assets/images/themes/default/svg/handshake.svg',
          name: 'handshake'
        },
        {
          img_path: '/assets/images/themes/default/svg/light.svg',
          name: 'light'
        },
        {
          img_path: '/assets/images/themes/default/svg/people_company.svg',
          name: 'people_company'
        },
        {
          img_path: '/assets/images/themes/default/svg/rocket.svg',
          name: 'rocket'
        },
        {
          img_path: '/assets/images/themes/default/svg/search.svg',
          name: 'search'
        },
        {
          img_path: '/assets/images/themes/default/svg/smartphone.svg',
          name: 'smartphone'
        },
        {
          img_path: '/assets/images/themes/default/svg/target.svg',
          name: 'target'
        },
        {
          img_path: '/assets/images/themes/default/svg/worksheet.svg',
          name: 'worksheet'
        }
      ],

      colorPickerOptions: [
        '#1595CE',
        '#FA6A6A',
        '#9955ED',
        '#FEA24B',
        '#14CCA8',
        '#7BF27F',
        '#75C1DD',
        '#EA54C7',
        '#0048C7',
        '#F7DD19'
      ],

      iconPickerOptions: []
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.id
    }
  },

  validations: {
    formData: {
      name: {
        required
      },

      alias: {
        required
      },

      color: {
        required
      }

      // icon: {
      //   required
      // }
    }
  },

  created () {
    this.populateIcons()

    if (this.isEditing) {
      if (!this.$route.params.theme) {
        this.setFeedback({ message: this.$t('management.themes:error.loading.failed') })
        this.close()
      } else {
        this.formData = this.$route.params.theme
      }
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptCreateTheme',
      'attemptUpdateTheme'
    ]),

    close () {
      this.$router.push({
        name: 'management.index',
        params: { activeTab: 'themes' }
      })
    },

    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.isEditing) {
        this.submitUpdate()
      } else {
        this.submitCreate()
      }
    },

    submitCreate () {
      this.setFetching(true)

      this.attemptCreateTheme(this.formData)
        .then(({ data }) => {
          this.formData = data

          this.$router.replace({
            name: 'management.themes.manage',
            params: {
              id: data.id,
              theme: data
            }
          })

          this.close()
        })
        .catch(({ data }) => {
          this.setFeedback({ message: this.$t(`management.themes:error.${data.error.message}`) })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    submitUpdate () {
      this.setFetching(true)

      this.attemptUpdateTheme(this.formData)
        .then(({ data }) => {
          this.formData = data
          this.close()
        })
        .catch(({ data }) => {
          this.setFeedback({ message: this.$t(`management.themes:error.${data.error.message}`) })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    populateIcons () {
      const images = require.context(
        '/public/assets/images/themes/default/svg/themes/',
        false,
        /\.(svg)$/
      )

      images.keys().forEach((value) => {
        this.iconPickerOptions.push(value.slice(2))
      })
    }
  }
}
</script>

<template>
  <Modal
    :active="true"
    :cancel="true"
    @close="close"
  >
    <div class="modal-form modal-manage-theme">
      <span class="modal-subtitle">{{ $t('management:themes.manage.title') }}</span>
      <h2 class="modal-title">
        {{ $t(`management:themes.manage.description.editing.${isEditing}`) }}
      </h2>
      <form @submit.prevent="submit">
        <InputField
          v-model="formData.name"
          :label="$t('management:themes.manage.name')"
          :validation="$v.formData.name"
          :counter="20"
          dark
        />

        <InputField
          v-model="formData.alias"
          :label="$t('management:themes.manage.alias')"
          :validation="$v.formData.alias"
          :counter="250"
          dark
        />

        <ColorPicker
          v-model="formData.color"
          :label="$t('management:themes.manage.color')"
          :validation="$v.formData.color"
          :options="colorPickerOptions"
          class="form-item"
          dark
        />

        <!--
 <IconPicker
          v-model="formData.icon"
          :label="$t('management:themes.manage.icon.description')"
          :validation="$v.formData.icon"
          :options="iconPickerOptions"
          context="/assets/images/themes/default/svg/themes/"
          class="form-item"
          dark
        />
-->

        <Action
          :text="$t('global:form.save')"
          type="button"
          secondary
          large
          submit
          fixed-width
        />
      </form>
    </div>
  </Modal>
</template>

<style>
.modal-manage-theme form {
  text-align: center;
}

.modal-manage-theme .btn {
  margin-top: 40px;
}

.upload-description {
  text-align: left;
}
</style>
